import classes from './about.module.scss';
import Fade from 'react-reveal/Fade';

import { smilePhoto } from 'images/Photo';
import { Link } from 'components';
import Skills from './Skills';
import SocialLinks from '../Contact/SocialLinks';

export default function About() {
  return (
    <section className={classes.about} id='about'>
      <div className={classes.container}>
        <h3 className={classes.heading}>About me</h3>
        <Fade bottom distance={'4rem'}>
          <div className={classes.about__content}>
            <figure className={classes.profile}>
              <img className={classes.image} alt='Azaz Ahmed' src={smilePhoto} />
            </figure>
            <div className={classes.description}>
              <p>
                <span role='img' aria-label='wave emoji'>
                  👋
                </span>{' '}
                Hello, world! I am Md. Azaz Ahmed, a Full Stack Web Developer with 2+ years of experience. I specialize
                in creating innovative and user-friendly web solutions. With expertise in front-end technologies like
                React and Next.js, I build complex functional UIs that deliver exceptional user experiences.
              </p>
              <p>
                I love to design and code{' '}
                <span role='img' aria-label='code emoji'>
                  👨🏼‍💻
                </span>
                . I build high-quality applications for the web. I concentrate on writing clean, understandable and
                testable code.
              </p>
              <p>
                I have a Bachelor's degree in{' '}
                <span role='img' aria-label='code graduate'>
                  👨‍🎓
                </span>{' '}
                Computer Science and Engineering from{' '}
                <Link to='https://daffodilvarsity.edu.bd/'>Daffodil International University, Dhaka</Link>. I love
                working in and as a team. I'm always looking for opportunities to learn and grow.
              </p>
              <p className={classes.description}>
                Other than coding, I'm passionate about Traveling{' '}
                <span role='img' aria-label='galaxy emoji'>
                  ✈️
                </span>
                , Movies{' '}
                <span role='img' aria-label='movies'>
                  🎬
                </span>
                , and Biking{' '}
                <span role='img' aria-label='cook emoji'>
                  🏍️
                </span>
                . I love cats{' '}
                <span role='img' aria-label='cat face emoji'>
                  😺
                </span>{' '}
                and adore all pet animals{' '}
                <span role='img' aria-label='pet dog emoji'>
                  🐕
                </span>
                .
              </p>
            </div>
          </div>

          <Skills />
        </Fade>

        <Fade bottom>
          <div className={classes.socialLinks}>
            <SocialLinks />
          </div>
        </Fade>
      </div>
    </section>
  );
}

