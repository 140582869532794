import { Hero, About, Projects, Contact, Footer, WorkExperiences } from 'sections';
import { NavigationBar } from 'components';

export default function Portfolio() {
  return (
    <>
      {/* <Loader style={isLoading ? {} : { opacity: 0, visibility: 'hidden' }} /> */}
      <NavigationBar />
      <main>
        <Hero />
        <About />
        <WorkExperiences />
        <Projects />
        {/* <OtherProjects /> */}
        {/* <Statistics /> */}
        <Contact />
      </main>
      <Footer />
    </>
  );
}

