import { Fade } from 'react-reveal';
import classes from './Experience.module.scss';
import classNames from 'classnames';
import Tech from 'components/Tech';
/**
 * @typedef {Object} ExperienceProps
 * @property {string} duration - The duration of the experience.
 * @property {string} location - The location of the experience.
 * @property {string} logo - The logo of the company.
 * @property {string} role - The role in the company.
 * @property {string} companyName - The name of the company.
 * @property {string} companyLink - The link to the company's website.
 * @property {string[]} responsibilities - The responsibilities in the role.
 * @property {string[]} techStack - The technology stack used in the role.
 */
export default function Experience(props) {
  const { duration, location, logo, role, companyName, companyLink, responsibilities, techStack } = props;
  return (
    <Fade bottom distance={'4rem'}>
      <div className={classes.experience}>
        <div className={classNames(classes.experience__timeline, classes.marginBottom)}>
          <p>{duration}</p>
          <p>{location}</p>
        </div>
        <div className={classes.experience__logoSection}>
          <img src={logo} className={classes.experience__logo} alt='Company Logo' />
        </div>
        <div className={classNames(classes.experience__details, classes.marginBottom)}>
          <p className={classes.experience__dateLocation}>
            {duration} ({location})
          </p>
          <div className={classes.experience__role}>{role}</div>
          <h4 className={classes.experience__title}>
            <a href={companyLink}>{companyName}</a>
          </h4>

          <Responsibilities list={responsibilities} />

          <div className={classes.experience__techStack}>
            {techStack.map((value, index) => (
              <Tech value={value} key={index} />
            ))}
          </div>
        </div>
      </div>
    </Fade>
  );
}

function Responsibilities({ list }) {
  return (
    <Fade bottom distance={'2rem'}>
      <ul className={classes.responsibilities}>
        {list.map((item, key) => (
          <li className={classes.responsibility} key={key}>
            {item}
          </li>
        ))}
      </ul>
    </Fade>
  );
}

