import data from './data';
import classes from './workExperiences.module.scss';

import Experience from 'components/Experience';

export default function WorkExperiences() {
  return (
    <section className={classes.workExperiences} id='work_experiences'>
      <div className={classes.container}>
        <h3 className={classes.heading}>Work Experiences</h3>
        <div className={classes.wrapper}>
          {data.map((experience, index) => {
            const {
              duration,
              location,
              logo,
              role,
              companyName,
              companyLink,
              responsibilities,
              techStack
            } = experience;
            return (
              <Experience
                key={index}
                duration={duration}
                location={location}
                logo={logo}
                role={role}
                companyName={companyName}
                companyLink={companyLink}
                responsibilities={responsibilities}
                techStack={techStack}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
}

