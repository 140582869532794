import classes from './social.module.scss';

export default function SocialLinks() {
  return (
    <div className={classes.social}>
      <a href='mailto: akibahmed2011@gmail.com' className='icon'>
        &#xe910;
      </a>
      <a href='https://github.com/akib001' className='icon'>
        &#xe908;
      </a>
      <a href='https://www.linkedin.com/in/azaz01/' className='icon'>
        &#xe90a;
      </a>
      <a href='https://facebook.com/akibahmed2011' className='icon'>
        &#xe905;
      </a>
      {/* <a href='https://www.instagram.com/akib6735/' className='icon'>
      &#xe90e;
      </a> */}
    </div>
  );
}
