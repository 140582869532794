import { SoftBdLogo } from 'images/ExperienceSection';

const softBdSoftwareEngineer = {
  duration: 'Sep 2015 – Present',
  location: 'Dhaka, Bangladesh',
  logo: SoftBdLogo,
  role: 'Software Engineer',
  companyName: 'SoftBd Ltd',
  companyLink: 'https://softbdltd.com',
  responsibilities: [
    'Improved frontend performance by 37% for 70,000+ daily users by streamlining data fetching, caching, code splitting, and reducing bundle size, resulting in faster load times and better user experience',
    'Created a drag-and-drop workflow builder using React, which streamlined organizational approval processes like leave',
    'Architected a scalable notification microservice using NestJS, RabbitMQ for real-time in-app, email, and SMS delivery',
    'Developed real-time notifications and chat with Socket.io, enhancing internal communication',
    'Optimized dashboard APIs with Redis caching, MySQL query tuning, and indexing, improving API response times by 53% and enhancing database efficiency',
    'Developed a dynamic form builder with React Hook Form and Yup, enabling real-time form creation and custom validation, streamlining development and increasing flexibility'
  ],
  techStack: ['React', 'NextJs', 'TypeScript', 'MUI', 'Nodejs', 'Nestjs', 'TypeORM', 'Mysql', 'Mongodb']
};

const softBdJunior = {
  duration: 'Sep 2015 – Jan 2023',
  location: 'Dhaka, Bangladesh',
  logo: SoftBdLogo,
  role: 'Junior Software Engineer',
  companyName: 'SoftBd Ltd',
  companyLink: 'https://softbdltd.com',
  responsibilities: [
    'Designed a comprehensive landing page from Figma, incorporating visually appealing charts, forms, maps, sliders, and stepper components with pixel-perfect responsiveness',
    'Architected a scalable role-based access control system using Redux, managing permissions for millions of users',
    'Enhanced and maintained automated test suites using Jest and React Testing Library, achieving 67% code coverage',
    'Leveraged Next.js static site generation (SSG) and incremental static regeneration (ISR) to enhance performance and SEO for content-heavy pages',
    'Enhanced accessibility by integrating ARIA landmarks and semantic HTML, achieving WCAG 2.1 AA compliance'
  ],
  techStack: ['JavaScript', 'TypeScript', 'React', 'NextJs', 'Redux', 'MUI', 'Jest']
};

// const glonzy = {
//   duration: 'Apr 2019 - May 2020',
//   location: 'Dhaka, Bangladesh',
//   logo: GlonzyLogo,
//   role: 'Chief Executive Officer',
//   companyName: 'Glonzy',
//   companyLink: 'https://glonzy.com',
//   responsibilities: [
//     'Designed and implemented eCommerce websites in WordPress, along with creating marketing strategies.',
//     'Market analysis, Choosing products, Product presentation & showcase, Stock management, Product illustration & viable product placement on the site.',
//     'Making social media presence, Facebook marketing, Google Marketing, and Organic sales.',
//     'Turning an initial investment of 1000 USD into a thriving business with monthly sales of over 400 USD regularly and reaching revenue of 4000 USD  in one particular month.'
//   ],
//   techStack: ['Wordpress', 'Social Media Marketing', 'Business Development', 'Digital Marketing']
// };

const data = [softBdSoftwareEngineer, softBdJunior];
export default data;
