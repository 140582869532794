import React from 'react';
import classes from './hero.module.scss';
import { Link } from 'components';

export default function Hero() {
  return (
    <section className={classes.hero}>
      <div className={classes.container}>
        <h5 className={classes.intro}>
          <span role='img' aria-label='wave emoji'>
            👋
          </span>
          Hi, my name is
        </h5>
        <h1 className={classes.hero__name}>
          <span className={classes.emphasize}>Azaz</span>
          <span> Ahmed</span>
        </h1>

        <h2 className={classes.info}>
          a full-stack web developer passionate about building high-performance, scalable applications using modern
          technologies for seamless user experiences
          <span role='img' aria-label='codding with laptop'>
            👨‍💻
          </span>
        </h2>
      </div>

      <div role='img' className={classes.waterMark}>
        AZAZ
      </div>

      <div className={classes.quickContact}>
        <Link to='mailto: azazahmedakib@gmail.com' lone>
          azazahmedakib@gmail.com
        </Link>
        <Link to='https://www.linkedin.com/in/azaz01/' lone>
          linkedin.com/in/azaz01/
        </Link>
        <Link to='https://github.com/akib001' lone>
          github.com/akib001
        </Link>
        <Link to='https://gitlab.com/azazahmed' lone>
          gitlab.com/azazahmed
        </Link>
      </div>
    </section>
  );
}
