import { PizzabhaiLogo, PizzabhaiDark } from '../../images/Pizzabhai';
import { SynthefiLogo, Synthefi } from '../../images/Synthefi';
import { ShareHome, ShareHomeLogo } from '../../images/ShareHome';
import { DiuQuizLogo, DiuQuiz } from '../../images/DiuQuiz';

const diuQUiz = {
  title: 'DiuQuiz',
  type: 'MERN Stack',
  logo: DiuQuizLogo,
  images: {
    light: { small: DiuQuiz, large: DiuQuiz },
    dark: { small: DiuQuiz, large: DiuQuiz }
  },
  description:
    'Designed, developed and deployed a MERN stack web app for University teachers where they can create, manage, get results and overview analytics of quizzes and students can see their available quizzes, attend quizzes and overview their results on the dashboard.',
  features: [
    'Students and Teachers Dashboard',
    'Quiz Creation Feature',
    'Quiz Analytics for Teachers',
    'REST Api & Authentication in Nodejs',
    'Fully Responsive Design with MUI',
    'Developed Frontend with Nextjs, React'
  ],
  techStack: ['Nodejs', 'React', 'Nextjs', 'MUI', 'Express', 'Mongoose'],
  link: 'https://diu-quiz.vercel.app/',
  clientCode: 'https://github.com/akib001/DIU-Quiz',
  serverCode: 'https://github.com/akib001/DIU-Quiz-Backend',
  code: undefined
};

const pizzabhai = {
  title: 'PizzaBhai',
  type: 'MERN Stack',
  logo: PizzabhaiLogo,
  images: {
    light: { small: PizzabhaiDark, large: PizzabhaiDark },
    dark: { small: PizzabhaiDark, large: PizzabhaiDark }
  },
  description:
    'A MERN stack web application for restaurants where customers can see the food menu, order, authenticate and see their orders dashboard and owners can create, delete, update the food menu and review orders.',
  features: [
    'React Components',
    'Redux State Management',
    'CRUD Operation',
    'Authentication system',
    'REST API',
    'Image Upload In Firestore'
  ],
  techStack: ['React', 'Redux', 'NodeJs', 'Express', 'MongoDB'],
  link: 'https://pizzabhai.vercel.app',
  clientCode: 'https://github.com/akib001/PizzaBhai',
  serverCode: 'https://github.com/akib001/PizzaBhai-Backend',
  code: undefined
};

const shareHome = {
  title: 'ShareHome',
  type: 'MERN Stack',
  logo: ShareHomeLogo,
  images: {
    light: { small: ShareHome, large: ShareHome },
    dark: { small: ShareHome, large: ShareHome }
  },
  description:
    'A MERN stack web application for users to search, post, review and rent vacation home rentals.',
  features: ['Next Js serverside Rendering', 'Authentication system', 'CRUD Operation', 'Redux State Management', 'REST Api', 'Mapbox map location'],
  techStack: ['NextJs', 'TailwindCSS', 'Redux', 'Express', 'Mongo DB'],
  link: 'https://sharehome.vercel.app/',
  clientCode: 'https://github.com/akib001/airbnb',
  serverCode: 'https://github.com/akib001/ShareHome-Backend',
  code: undefined
};


const synthefi = {
  title: 'SynThefi',
  type: 'Web 3.0',
  logo: SynthefiLogo,
  images: {
    light: { small: Synthefi, large: Synthefi },
    dark: { small: Synthefi, large: Synthefi }
  },
  description:
    'Web 3.0 Dapp for trading crypto-token in a decentralized environment.',
  features: ['Modern design', 'Online Form downloads', 'Reusable web components', 'Image Gallery'],
  techStack: ['NextJS', 'Tailwind', 'Solidity', 'Sanity', 'Moralis'],
  link: 'https://synthefi.netlify.app/',
  clientCode: undefined,
  serverCode: undefined,
  code: 'https://github.com/akib001/SyntheFI'
};

const data = [diuQUiz, pizzabhai, shareHome, synthefi];
export default data;
